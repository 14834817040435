<template>
  <v-col sm="1">
    <v-dialog v-model="dialog" max-width="40%" persistent>
      <v-card>
        <v-form ref="form" v-model="valid">
          <v-toolbar dark :color="$cv('principal')">
            <v-btn icon dark @click="$emit('terminar')">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title title="Adquirir Saldo en Cuenta Corriente">
              Adquirir Saldo en Cuenta Corriente
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn dark text @click="save" :disabled="!valid">Guardar</v-btn>
            </v-toolbar-items>
          </v-toolbar>

          <v-card-text>
            <v-container>
              <v-row >
                <v-col
                  cols="12"
                  md="8"
                  sm="12"
                  class="text-h5 black--text flex justify-content-center align-content-center"
                >
                  <b>{{ editedItem.nombre }}</b>
                </v-col>
                <v-col cols="12" md="4" sm="12">
                  <vuetify-money
                    v-model="editedItem.precio"
                    autofocus
                    label="Ingresar Monto"
                    :options="options"
                    :rules="[$rulesFloatRequerido]"
                    @keyup.enter="save"
                    @keyup="updateSubtotal()"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-form>
      </v-card>
    </v-dialog>
  </v-col>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
    action: {
      type: String,
      required: true,
      default: "",
    },
  },

  data() {
    return {
      options: {
        locale: "es-AR",
        prefix: "$",
        suffix: "",
        length: 11,
        precision: 2,
      },
      editedItem: {
        nombre: "",
        id: "",
      },
      valid: true,
      dialog: true,
      readonly: true,
      readOnlySubtotal: true,
    };
  },

  computed: {
    ...mapState("carrito", ["carrito", "total"]),
  },

  methods: {
    ...mapMutations("carrito", ["addProducto", "editProducto"]),

    updateSubtotal(item = false) {
      if (item) {
        this.editedItem = Object.assign({}, item);
      }
      // Calcula el nuevo subtotal
      this.editedItem.subtotal = Number(this.editedItem.precio);

      console.log(this.editedItem.subtotal);
    },
    save() {
      console.log(this.action);
      if (this.action == "AGREGAR PRODUCTO") {
        this.addProducto(this.editedItem);
        return this.$emit("terminar");
      }
      if (this.action == "EDITAR PRODUCTO") {
        this.editProducto(this.editedItem);
        //console.log("luego en editar");
        //console.log(this.editedItem);
        return this.$emit("terminar");
      }
    },
  },

  created() {
    this.editedItem = this.item;
    this.editedItem.cantidad = 1;
    this.dialog = true;
  },
  mounted() {},

  destroyed() {},
};
</script>

<style scoped></style>
